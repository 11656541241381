import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import * as es from './assets/locale/es/*.json'
import * as en from './assets/locale/en/*.json'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es,en
    },
    fallbackLng: 'es',
    lng:'es',
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;