import React from 'react';
import {
  Grid,
  Typography,
  Button,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  useTheme
} from '@material-ui/core';
import { useContext, useApi } from '../context';
import { useHistory, Redirect } from 'react-router-dom';
import utils from '../services/utils.service';
import { AlertDialog } from '../components/alertDialog';
import { TransferWizard, TransferWizardStep } from './wizard';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Region } from '../services/types/region.type';
import { Autocomplete } from '@material-ui/lab';
import {
  Promotion,
  PromotionType,
  PromotionVerificationError
} from '../services/types/promotion.type';
import { LoadingButton } from '../components/loadingButton';
import HelpIcon from '@material-ui/icons/ContactSupport';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: theme.spacing(0.25, 1),
    },
    listItemText: {
      margin: theme.spacing(0)
    }
  }),
);


const RegionSelectionModal = (props: { open: boolean; regions: Region[]; onClose: (region: Region | null) => void }) => {
  const { open, regions, onClose } = props;
  const [selectedRegion, selectRegion] = React.useState<Region | null>(null);
  const { t } = useTranslation('checkout');
  return (
    <Dialog fullWidth open={open} onClose={() => onClose(selectedRegion)}>
      <DialogTitle>{t('SELECT_REGION_MODAL_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('SELECT_REGION_MODAL_DESCRIPTION')}
        </DialogContentText>
        <Autocomplete
          value={selectedRegion}
          onChange={(event, value) => {
            selectRegion(value);
          }}
          getOptionSelected={(option, value) => option.code === value.code}
          options={regions}
          autoHighlight
          getOptionLabel={(option) => option.name}
          renderOption={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('STATE_OR_PROVINCE')}
              variant='outlined'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'chrome-off',
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(selectedRegion)} color='primary' autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>

  )
}

export const Checkout = () => {
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const { t, i18n } = useTranslation(['checkout', 'deliveryMethod', 'accountType', 'documentTypes', 'promotions']);
  moment.locale(i18n.language);

  const productSelectionParams = context.data.productSelectionParams;
  const beneficiary = context.data.beneficiary;
  const attributes = context.data.productAttributes;
  const concept = context.data.concept;
  const quotation = context.data.quotation;

  if (
    !productSelectionParams ||
    !beneficiary ||
    !concept ||
    !quotation
  ) {
    return (
      <Redirect to='/' />
    )
  }

  const [loading, setLoading] = React.useState(false);
  const [showRegionSelectionModal, setShowRegionSelectionModal] = React.useState(false);
  const [regions, setRegions] = React.useState<Region[]>([]);
  const [error, setError] = React.useState(false);

  const [promotion, setPromotion] = React.useState<Promotion | null>(null);

  const [hasAppliedVoucher, setHasAppliedVoucher] = React.useState(false);
  const [voucherCode, setVoucherCode] = React.useState('');
  const [voucherError, setVoucherError] = React.useState<PromotionVerificationError | null>(null);
  const [applyingVoucher, setApplyingVoucher] = React.useState(false);

  const [showPromoDetail, setShowPromoDetails] = React.useState(false);


  React.useEffect(() => {
    utils.runAsync(async () => {
      const promotion = await api.getBestPromotion(quotation.product_id, context.data.productSelectionParams!.sourceCurrency.id);
      setPromotion(promotion);
    }, () => {
      setLoading(false);
    });
  }, []);


  const getPromoTitle = () => {
    const p = promotion;
    if (!p) return '';
    if (p.type === PromotionType.REGULAR) {
      return t('promotions:REGULAR_PROMO_TITLE')
    } else if (p.type === PromotionType.WELCOME) {
      return t('promotions:WELCOME_PROMO_TITLE')
    } else {
      return t('promotions:VOUCHER_PROMO_TITLE')
    }
  }

  const getPromoDescription = () => {
    const p = promotion;
    if (!p) return '';
    const attributes = {
      start: moment(p.start).format('lll'),
      end: moment(p.end).format('lll'),
      usages: p.usages,
      amount: `${utils.currencyFormat(p.amount)} ${context.data.productSelectionParams!.sourceCurrency.iso}`,
      voucher: p.voucher
    };
    if (p.type === PromotionType.REGULAR) {
      return t(
        'promotions:REGULAR_PROMO_DESCRIPTION_WITHOUT_DESTINATION',
        attributes
      )
    } else if (p.type === PromotionType.WELCOME) {
      return t(
        'promotions:WELCOME_PROMO_DESCRIPTION_WITHOUT_DESTINATION',
        attributes
      )
    } else {
      return t(
        'promotions:VOUCHER_PROMO_DESCRIPTION_WITHOUT_DESTINATION',
        attributes
      )
    }
  }


  const applyVoucher = () => {
    setApplyingVoucher(true);
    utils.runAsync(async () => {
      const response = await api.getVoucherPromotion(voucherCode, quotation.product_id, context.data.productSelectionParams!.sourceCurrency.id);
      if (response.promotion) {
        setHasAppliedVoucher(true);
        setPromotion(response.promotion);
      } else {
        setVoucherError(response.error!);
      }
    }, () => {
      setApplyingVoucher(false);
    })
  }


  const checkout = (region?: Region) => {
    setLoading(true);
    utils.runAsync(async () => {
      const profile = await api.getMyProfile();
      const countries = await api.getCountries();
      const userCountry = countries.find(c => c.id === profile.kyc.data.id_card_country_id);
      if (userCountry?.iso3 === 'USA' || userCountry?.iso3 === 'CAN') {
        const regions = await api.getRegions(userCountry.id);
        const userRegion = await regions.find(r => r.code === profile.profile.data.region);
        if (!userRegion && !region) {
          setRegions(regions);
          setShowRegionSelectionModal(true);
          return;
        }
      }


      const checkoutResponse = await api.checkout(
        'PAYLANDS',
        concept,
        quotation.quotation_id,
        {
          first_name: beneficiary.name,
          last_name: beneficiary.lastName,
          address: beneficiary.address,
          city: beneficiary.city,
          region: beneficiary.region,
          postal_code: beneficiary.postalCode,
          residence_country_id: beneficiary.countryOfResidence.id,
          id_card_type: beneficiary.idCardType,
          id_card_number: beneficiary.idCardNumber,
          id_card_country_id: beneficiary.idCardIssueCountry.id,
          id_card_issue_date: beneficiary.idCardIssueDate,
          id_card_expiry_date: beneficiary.idCardExpiryDate,
          phone: beneficiary.phone,
          email: beneficiary.email,
          account_fields: attributes?.account?.fields,
          account_type_id: attributes?.account?.type.id
        },
        attributes?.location?.id,
        region?.code,
        promotion?.id
      );
      window.location.replace(checkoutResponse.paylands_payment_url!);
    }, (e) => {
      if (e) {
        setError(true);
        setLoading(false);
      }
    });
  }

  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <RegionSelectionModal
        open={showRegionSelectionModal}
        regions={regions}
        onClose={(region: Region | null) => {
          setShowRegionSelectionModal(false);
          if (region !== null) {
            checkout(region);
          }
        }} />
      <TransferWizard canGoNext={!loading} loading={loading} back={() => history.push('/transfer/beneficiary')} next={() => checkout()} step={TransferWizardStep.CHECKOUT} showButtons={context.data.auth.isSignedIn}>
        <AlertDialog
          open={voucherError !== null}
          onClose={() => setVoucherError(null)}
          title={t('COULD_NOT_APPLY_VOUCHER')}
          message={t(voucherError ? voucherError : '')} />
        <AlertDialog
          open={showPromoDetail}
          onClose={() => setShowPromoDetails(false)}
          title={getPromoTitle()}
          message={getPromoDescription()} />
        <AlertDialog
          open={error}
          title={t('ERROR_TITLE')}
          message={t('ERROR_CONTENT')}
          onClose={() => {
            setError(false)
          }}
        />
        <RegionSelectionModal
          open={showRegionSelectionModal}
          regions={regions}
          onClose={(region: Region | null) => {
            setShowRegionSelectionModal(false);
            if (region !== null) {
              checkout(region);
            }
          }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('CHECKOUT_INTRO')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {t('Delivery')}
            </Typography>
            <Grid item xs={12} container>
              <Grid item xs={12} sm={6}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={t('Country')} secondary={context.data.productSelectionParams?.country.name} className={classes.listItemText} />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={t('Amount')}
                      secondary={`${utils.currencyFormat(quotation.receive_amount)} ${context.data.productSelectionParams?.currency.iso}`} className={classes.listItemText}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={t('Delivery method')}
                      secondary={t(`deliveryMethod:${context.data.productSelectionParams!.transactionType.code}`)} className={classes.listItemText}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <List disablePadding>
                  {context.data.productAttributes?.agent &&
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('Agent')} secondary={context.data.productAttributes!.agent.name} className={classes.listItemText} />
                    </ListItem>
                  }
                  {context.data.productAttributes?.location &&
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={t('Branch')}
                        secondary={context.data.productAttributes?.location?.name}
                        className={classes.listItemText}
                      />
                    </ListItem>
                  }
                  {context.data.productAttributes?.account &&
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={t('Account')}
                        secondary={`${t(`accountType:${context.data.productAttributes.account.type.name}`)} ${context.data.productAttributes.account.fields.map(i => i.value).join(' - ')}`}
                        className={classes.listItemText}
                      />
                    </ListItem>
                  }
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {t('Beneficiary')}
            </Typography>
            <Grid item xs={12} container>
              <Grid item xs={12} sm={6}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={t('Identification')}
                      secondary={
                        <>
                          <Typography variant='body2'>{`${context.data.beneficiary?.name} ${context.data.beneficiary?.lastName}`}</Typography>
                          <Typography variant='body2'>{context.data.beneficiary?.countryOfResidence.name}</Typography>
                          <Typography variant='body2'>{`${t(`documentTypes:${context.data.beneficiary?.idCardType}`)} ${context.data.beneficiary?.idCardNumber}`}</Typography>
                          <Typography variant='body2'>{`${t('ISSUED')} ${context.data.beneficiary?.idCardIssueDate ? moment(context.data.beneficiary?.idCardIssueDate).format('MM-YYYY') : ''}`}</Typography>
                          <Typography variant='body2'>{`${t('EXPIRES')} ${context.data.beneficiary?.idCardExpiryDate ? moment(context.data.beneficiary?.idCardExpiryDate).format('MM-YYYY') : ''}`}</Typography>
                        </>
                      }
                      className={classes.listItemText}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={t('Contact data')}
                      secondary={
                        <>
                          <Typography variant='body2'>{context.data.beneficiary?.phone}</Typography>
                          <Typography variant='body2'>{context.data.beneficiary?.email}</Typography>
                        </>
                      }
                      className={classes.listItemText} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={t('Address')}
                      secondary={
                        <>
                          <Typography variant='body2'>{context.data.beneficiary?.address}</Typography>
                          <Typography variant='body2'>{`${context.data.beneficiary?.postalCode ? context.data.beneficiary.postalCode + ',' : ''} ${context.data.beneficiary?.city}, ${context.data.beneficiary?.region}`}</Typography>
                          <Typography variant='body2'>{context.data.beneficiary?.countryOfResidence.name}</Typography>
                        </>
                      }
                      className={classes.listItemText} />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={t('Concept')} secondary={context.data.concept} className={classes.listItemText} />
                  </ListItem>
                </List>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {t('Payment')}
            </Typography>
            <Grid item xs={12} container justify='center' alignItems='flex-end' spacing={1} style={{ marginBottom: 10 }}>
              <Grid item xs={8} md={4}>
                <TextField disabled={hasAppliedVoucher} value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} fullWidth label={t('VOUCHER_LABEL')} />
              </Grid>
              <Grid item>
                <LoadingButton
                  disabled={voucherCode.length === 0 || applyingVoucher || hasAppliedVoucher}
                  loading={applyingVoucher}
                  onClick={() => applyVoucher()}
                >
                  {t('APPLY_VOUCHER')}
                </LoadingButton>
              </Grid>
            </Grid>
            <List disablePadding>
              <ListItem className={classes.listItem}>
                <ListItemText primary={t('TOTAL_COST')} />
                <Typography variant='subtitle1' component='span'>{`${utils.currencyFormat(quotation.cost)} ${productSelectionParams.sourceCurrency.iso}`}</Typography>
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText primary={t('EXCHANGE_RATE')} />
                <Typography variant='subtitle1' component='span'>{`${utils.currencyFormat(1)} ${context.data.productSelectionParams?.sourceCurrency.iso} =  ${utils.currencyFormat(context.data.productSelectionParams!.exchangeRate, 4)} ${context.data.productSelectionParams?.currency.iso}`}</Typography>
              </ListItem>
              {promotion &&
                <ListItem className={classes.listItem}>
                  <ListItemText primary={t('PROMOTION')} />
                  <Typography variant='subtitle1' component='span'>{`${utils.currencyFormat(promotion.amount)} ${productSelectionParams.sourceCurrency.iso}`}</Typography>
                </ListItem>
              }
              <ListItem className={classes.listItem}>
                <ListItemText primary={t('TOTAL_PAY')} primaryTypographyProps={{ variant: 'h6', component: 'span' }} />
                <Typography variant='h5' component='span'>{`${utils.currencyFormat(quotation.payment_amount - (promotion?.amount ?? 0))} ${productSelectionParams.sourceCurrency.iso}`}</Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </TransferWizard>
    </>
  );
}
