import { hot } from 'react-hot-loader';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Router } from './router';
import { RevolusendProvider } from './context';
import { DatePickerProvider } from './components/datePicketProvider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


let theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Ubuntu',
      'sans-serif'
    ].join(','),
    subtitle1: {
      fontWeight: 600,
    },
    fontSize: 16

  },
  palette: {
    background: {
      default: 'transparent'
    },
    type: 'light',
    primary: {
      light: '#67D2E4',
      main: '#36B9D0',
      dark: '#24769d',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#1F627D',
      main: '#00415C',
      dark: '#042D3E',
      contrastText: '#ffffff',
    },
  },
});
theme = responsiveFontSizes(theme);

const App = () => (
  <React.Fragment>
    <DatePickerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RevolusendProvider>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY ?? ''}>
            <Router />
          </GoogleReCaptchaProvider>
        </RevolusendProvider>
      </ThemeProvider>
    </DatePickerProvider >
  </React.Fragment>
)

export default hot(module)(App);
