import React from 'react';
import { useContext } from '../context';
import { TransactionTypeCode } from '../services/types/transactionType.type';
import { Redirect } from 'react-router-dom';
import { AgentWithAccountSelection } from './options/agentWithAccountSelection';
import { AgentSelection } from './options/agentSelection';
import { NoOptions } from './options/noOptions';
import { CubaHomeDelivery } from './options/cubaHomeDelivery';
import { Country } from '../services/types/country.type';

export const Options = () => {
  const context = useContext();

  const getAttributeSelectionComponent = (transactionTypeCode: TransactionTypeCode, destinationCountry: Country) => {
    if ([
      TransactionTypeCode.BANK_ACCOUNT,
      TransactionTypeCode.BANK_ACCOUNT_WITH_LOCATIONS,
      TransactionTypeCode.MOBILE_MONEY,
      TransactionTypeCode.ATM,
      TransactionTypeCode.BANK_TRANSFER,
      TransactionTypeCode.ISO20022,
      TransactionTypeCode.MANUAL_TRANSFER,
    ].includes(transactionTypeCode)) {
      return <AgentWithAccountSelection />
    } else if ([TransactionTypeCode.CASH_TO_AGENT].includes(transactionTypeCode)) {
      return <AgentSelection />
    } else if ([
      TransactionTypeCode.CASH
    ].includes(transactionTypeCode)) {
      return <NoOptions />
    } else if (transactionTypeCode === TransactionTypeCode.HOME_DELIVERY && destinationCountry.iso2 === 'CU') {
      return <CubaHomeDelivery />
    } else if (transactionTypeCode === TransactionTypeCode.HOME_DELIVERY) {
      return <AgentSelection />
    }
  }

  if (!context.data.productSelectionParams) {
    return (<Redirect to={'/'} />)
  }


  return getAttributeSelectionComponent(
    context.data.productSelectionParams!.transactionType.code,
    context.data.productSelectionParams!.country
  );
}
