import { Context } from '../context';
import moment from 'moment';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';

export class Auth {

  private accessToken?: string;

  constructor(
    private readonly context: Context,
  ) { }


  public getAccessToken(): string {
    let accessToken: string | undefined;
    if (this.accessToken) {
      accessToken = this.accessToken;
    } else {
      accessToken = Cookies.get('_rp_token_');
    }
    if (accessToken && this.isAccessTokenValid(accessToken)) {
      return accessToken;
    } else if (accessToken) {
      this.logout();
    }
    throw new Error('No valid access token');
  }

  public attemptNonInteractiveLogin() {
    const accessToken = Cookies.get('_rp_token_');
    if (accessToken && this.isAccessTokenValid(accessToken)) {
      this.accessToken = accessToken;
      this.context.setData({ auth: { ready: true, isSignedIn: true } });
    } else {
      this.logout();
    }
  }

  logout() {
    this.accessToken = undefined;
    Cookies.remove('_rp_token_', { domain: '.sendity.com' })
    this.context.setData({ auth: { ready: true, isSignedIn: false } });
  }

  private isAccessTokenValid(accessToken: string): boolean {
    try {
      const exp = jwt.decode(accessToken).exp;
      return moment(exp * 1000).isAfter(moment());
    } catch (e) {
      return false;
    }
  }

}