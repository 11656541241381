import React from 'react';
import {
  Typography,
  Button,
  Grid,
  Container,
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useApi } from '../context';
import utils from '../services/utils.service';
import { Country } from '../services/types/country.type';
import { Currency } from '../services/types/currency.type';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircleFlag } from 'react-circle-flags';
import { Config } from '../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    pickDestinationWrapper: {
      margin: '0 auto',
      flexGrow: 1
    },
    pickDestinationHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textAlign: 'center'
    },
    img: {
      maxWidth: '100%',
    },
  })
)


const DestinationPickerBox = () => {
  const classes = useStyles();
  const { t,i18n } = useTranslation(['home','countries']);
  const api = useApi();
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedCountry, selectCountry] = React.useState<null | Country>(null);
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [sourceCurrencies, setSourceCurrencies] = React.useState<Currency[]>([]);
  const [selectedCurrency, selectCurrency] = React.useState<null | Currency>(null);
  const [loading, setLoading] = React.useState(true);
  const config = new Config();

  const { queryCountryIso, locale } = (() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      return {
        queryCountryIso: query.get('country') ? query.get('country')!! : null,
        locale: query.get('locale')==='en'?'en':'es'
      };
    } else {
      return {
        queryCountryIso: undefined,
        locale: 'es'
      }
    }
  })();

  React.useEffect(()=>{
    i18n.changeLanguage(locale);
  },[locale]);

  React.useEffect(() => {
    utils.runAsync(async () => {
      const cctries = await api.getProductCountries();
      setCountries(cctries);
      if (queryCountryIso) {
        const countryToSearch = cctries.find(c => c.iso3 === queryCountryIso || c.iso2 === queryCountryIso)
        if (countryToSearch) {
          selectCountry(countryToSearch);
        }
      } 
    }, () => {
      setLoading(false);
    });
    utils.runAsync(async () => {
      setSourceCurrencies(await (await api.getSourceCurrencies()).filter(c => c.iso === 'EUR'));
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    utils.runAsync(async () => {
      if (selectedCountry) {
        selectCurrency(null);
        const tmpCurrencies = await api.getCurrencies(selectedCountry.id)
        setCurrencies(tmpCurrencies);
        if (tmpCurrencies.length === 1) {
          selectCurrency(tmpCurrencies[0]);
        }
      } else {
        setCurrencies([]);
        selectCurrency(null);
      }
    }, () => {
      if (countries.length > 0) {
        setLoading(false);
      }
    });
  }, [selectedCountry]);

  return (
    <Card className={classes.pickDestinationWrapper}>
      {/* <CardHeader
                title={t('Where do you want to send money to?')}
                className={classes.pickDestinationHeader}
            /> */}
      <CardContent>
        <Grid container spacing={2}>
          {(countries.length > 0 || !loading) &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  selectCountry(value);
                }}
                value={selectedCountry}
                options={countries}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => t('countries:'+option.iso3)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option) => (
                  <React.Fragment>
                    <CircleFlag countryCode={option.iso2.toLowerCase()} height={25} cdnUrl="/" />
                    <span style={{ marginLeft: 10 }}>{t('countries:'+option.iso3)} ({option.iso2})</span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Choose a country')}
                    variant='outlined'
                    fullWidth={true}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedCountry ? (
                        <CircleFlag countryCode={selectedCountry.iso2.toLowerCase()} height={25} cdnUrl="/" />
                      ) : undefined
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>

          }
          {selectedCountry && !loading &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  selectCurrency(value);
                }}
                value={selectedCurrency}
                getOptionSelected={(option, value) => option.id === value.id}
                options={currencies}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.iso}
                renderOption={(option) => option.iso}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Choose a currency')}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
          }
          {selectedCountry && selectedCurrency && !loading &&
            <>
              <Grid item xs={12}>
                {sourceCurrencies.map(c => (
                  <Typography variant='h4' component='p' align='center' key={c.iso}>
                    {`${utils.currencyFormat(1)} ${c.iso} = ${utils.currencyFormat(1 / selectedCurrency.rate * c.rate, 4)} ${selectedCurrency.iso}`}
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={12} container justify='flex-end'>
                <Button
                  component='a'
                  target='_parent'
                  href={`${config.BASE_URL}/transfer/destination?country_id=${selectedCountry.id.toString()}&currency_id=${selectedCurrency.id.toString()}`}
                  variant='outlined'
                  color='secondary'
                  size='large'
                >{t('CONTINUE')}</Button>
              </Grid>
            </>
          }
          {loading &&
            <Grid container justify='center'>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  );
}

export const Widget = () => {
  return (
    <Container>
      <DestinationPickerBox />
    </Container>
  )
}