import { RateReview } from '@material-ui/icons';
import { Config } from '../config';
import { Currency } from './types/currency.type';

const config = new Config();
const utils = {
  countryToFlag: (isoCode: string) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  },
  round: (aNumber: number) => Math.round((aNumber + Number.EPSILON) * 100) / 100,
  runAsync: (runnable: () => Promise<void>, onDone?: (error?: any) => void) => {
    runnable().then(() => {
      if (onDone) {
        onDone();
      }
    }).catch(e => {
      if (e) {
        console.error(e);
      }
      if (onDone) {
        onDone(e);
      }
    });
  },
  currencyFormat: (aNumber: number, fractionDigits: number = 2) => new Intl.NumberFormat(
    ['es-ES', 'en-GB', 'en-US'],
    { style: 'decimal', minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
  ).format(aNumber),

  shuffleArray: (arrayToShuffle: any[]) => {
    for (let i = arrayToShuffle.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayToShuffle[i], arrayToShuffle[j]] = [arrayToShuffle[j], arrayToShuffle[i]];
    }
    return arrayToShuffle;
  },

  convertToEurosByCurrencyId(
    currency: Currency,
    amount: number,
    rate: number
  ): number {
    if (currency.iso === 'EUR') {
      return amount;
    }
    return amount * rate;
  },

  convertFromEurosToCurrencyId(currency: Currency, amount: number, rate: number): number {
    if (currency.iso === 'EUR') {
      return amount;
    }
    return amount / rate;
  },

  getOrDefault: (value?: string, def = ''): string => {
    if (value) return value;
    return def;
  },

  getStaticMapImage: (center: { lat: number; long: number; }, marker: string | { lat: number; long: number }, zoom: number = 11, style: boolean = true): string => {
    const query = new URLSearchParams();
    query.append('key', config.GOOGLE_MAPS_API_KEY);
    query.append('center', `${center.lat.toString(10)},${center.long.toString(10)}`);
    query.append('zoom', zoom.toString(10));
    query.append('format', 'png');
    query.append('maptype', 'roadmap');
    if (style) {

      query.append('style', 'element:geometry|color:0x1d2c4d');
      query.append('style', 'element:labels.text.fill|color:0x8ec3b9');
      query.append('style', 'element:labels.text.stroke|color:0x1a3646');
      query.append('style', 'feature:administrative|element:geometry|visibility:off');
      query.append('style', 'feature:administrative.country|element:geometry.stroke|color:0x4b6878');
      query.append('style', 'feature:administrative.land_parcel|visibility:off');
      query.append('style', 'feature:administrative.land_parcel|element:labels.text.fill|color:0x64779e');
      query.append('style', 'feature:administrative.neighborhood|visibility:off');
      query.append('style', 'feature:administrative.province|element:geometry.stroke|color:0x4b6878');
      query.append('style', 'feature:landscape.man_made|element:geometry.stroke|color:0x334e87');
      query.append('style', 'feature:landscape.natural|element:geometry|color:0x023e58');
      query.append('style', 'feature:poi|visibility:off');
      query.append('style', 'feature:poi|element:geometry|color:0x283d6a');
      query.append('style', 'feature:poi|element:labels.text|visibility:off');
      query.append('style', 'feature:poi|element:labels.text.fill|color:0x6f9ba5');
      query.append('style', 'feature:poi|element:labels.text.stroke|color:0x1d2c4d');
      query.append('style', 'feature:poi.park|element:geometry.fill|color:0x023e58');
      query.append('style', 'feature:poi.park|element:labels.text.fill|color:0x3C7680');
      query.append('style', 'feature:road|visibility:off');
      query.append('style', 'feature:road|element:geometry|color:0x304a7d');
      query.append('style', 'feature:road|element:labels|visibility:off');
      query.append('style', 'feature:road|element:labels.icon|visibility:off');
      query.append('style', 'feature:road|element:labels.text.fill|color:0x98a5be');
      query.append('style', 'feature:road|element:labels.text.stroke|color:0x1d2c4d');
      query.append('style', 'feature:road.highway|element:geometry|color:0x2c6675');
      query.append('style', 'feature:road.highway|element:geometry.stroke|color:0x255763');
      query.append('style', 'feature:road.highway|element:labels.text.fill|color:0xb0d5ce');
      query.append('style', 'feature:road.highway|element:labels.text.stroke|color:0x023e58');
      query.append('style', 'feature:transit|visibility:off');
      query.append('style', 'feature:transit|element:labels.text.fill|color:0x98a5be');
      query.append('style', 'feature:transit|element:labels.text.stroke|color:0x1d2c4d');
      query.append('style', 'feature:transit.line|element:geometry.fill|color:0x283d6a');
      query.append('style', 'feature:transit.station|element:geometry|color:0x3a4762');
      query.append('style', 'feature:water|element:geometry|color:0x0e1626');
      query.append('style', 'feature:water|element:labels.text|visibility:off');
      query.append('style', 'feature:water|element:labels.text.fill|color:0x4e6d70');
    }
    query.append('size', '640x520');
    query.append('scale', '2');
    if (typeof marker === 'string') {
      if (style) {
        query.append('markers', `color:0xd1ba6c|${marker}`);
      } else {
        query.append('markers', `${marker}`);
      }
    } else {
      if (style) {
        query.append('markers', `color:0xd1ba6c|${marker.lat},${marker.long}`);
      } else {
        query.append('markers', `${marker.lat},${marker.long}`);
      }
    }
    return `https://maps.googleapis.com/maps/api/staticmap?${query.toString()}`
  },
  dataURItoBlob(dataURI: string): Blob {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }


}
export default utils
