import React from 'react';
import { Grid, Typography, Container, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import koImg from '../assets/img/error.svg'
import { useApi, useContext } from '../context';
import { LoadingButton } from '../components/loadingButton';
import utils from '../services/utils.service';
import { useParams } from 'react-router-dom';
import { AlertDialog } from '../components/alertDialog';

export const PaymentKo = () => {
  const { t } = useTranslation('payment');

  const { transactionId } = useParams<{ transactionId?: string }>();

  const context = useContext();
  const api = useApi();

  React.useEffect(() => {
    context.reset();
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const retryPayment = () => {
    if (!transactionId) return;
    utils.runAsync(async () => {
      setLoading(true);
      const response = await api.getPaymentUrlForTransaction(transactionId);
      window.location.replace(response.paylands_payment_url);
    }, (e) => {
      setLoading(false);
      if (e) {
        console.log(e);
        setError(true);
      }
    });
  }

  return (
    <>
      <AlertDialog
        open={error}
        onClose={() => setError(false)}
        message={t('ERROR_MESSAGE')}
        title={t('ERROR_TITLE')}
      />
      <Container style={{ height: '100%' }}>
        <Grid container justify='center' alignContent='center' spacing={4} style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Typography variant='h4' align='center'>
              {t('PAYMENT_KO_TITLE')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1' align='center'>
              {t('PAYMENT_KO_SUBTITLE')}
            </Typography>
          </Grid>
          <Grid item xs={12} container justify='center'>
            <img src={koImg} style={{ maxWidth: 200 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography align='center'>
              {t('PAYMENT_KO_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item container justify='center' xs={12}>
            <LoadingButton
              loading={loading}
              disabled={loading || !transactionId}
              onClick={retryPayment}
              variant='contained'
              color='primary'
              size='large'>
              {t('RETRY_PAYMENT')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}