import 'fontsource-roboto';
import 'fontsource-ubuntu';
import React from 'react';
import { render } from 'react-dom';
import App from './app';
import './i18n';
import 'react-typed/dist/animatedCursor.css';


render(<App />, document.getElementById('root'));

if (module.hot) {
    module.hot.accept();
}