import { Config } from '../config';
import { Country } from './types/country.type';
import { Currency } from './types/currency.type';
import { TransactionType } from './types/transactionType.type';
import { Agent } from './types/agent.type';
import { Location } from './types/location.type';
import { AccountType } from './types/acocuntType.type';
import { Beneficiary } from './types/beneficiary.type';
import { Auth } from './auth.service';
import { UserProfile } from './types/userProfile.type';
import { IdCardType } from './types/idCardType.enum';
import { Contact } from './types/contact.type';
import { Transaction } from './types/transaction.type';
import { Region } from './types/region.type';
import { LocationListItem } from './types/locationListItem.type'
import { PromotionListItem } from './types/promotionListItem.type';
import { Promotion, PromotionVerificationError } from './types/promotion.type';
import { SecurityQuestion } from './types/securityQuestion.type';
import { ExchangeMarkup } from './types/exchangeMarkup.type';
import { Quotation } from './types/quotation.type';
import { CubaZones } from './types/cubaZones.type';

export class Api {

  constructor(
    private readonly config: Config,
    private readonly auth: Auth,
  ) { }

  async getProductCountries(): Promise<Country[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/products/countries',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).countries;
    } else {
      throw new Error('Got wrong status code while fetching countries');
    }
  }

  async getCountries(): Promise<Country[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/countries',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).countries;
    } else {
      throw new Error('Got wrong status code while fetching countries');
    }
  }

  async getCurrencies(countryId: number): Promise<Currency[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/countries/${countryId}/currencies`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).currencies;
    } else {
      throw new Error('Got wrong status code while fetching countries');
    }
  }

  async getSourceCurrencies(): Promise<Currency[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/currencies/source`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).currencies;
    } else {
      throw new Error('Got wrong status code while fetching source currencies');
    }
  }

  async getTransactionTypes(countryId: number, currencyId: number): Promise<TransactionType[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/countries/${countryId}/currencies/${currencyId}/transaction-types`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).transaction_types;
    } else {
      throw new Error('Got wrong status code while fetching transaction types');
    }
  }

  async getQuotation(
    countryId: number,
    currencyId: number,
    sourceCurrencyId: number,
    transactionTypeCode: string,
    amount: number,
    mode: 'TOTAL_DUE'| 'SEND' | 'RECEIVE',
    agentId?: number
  ): Promise<Quotation> {

    const response = await fetch(
      this.config.API_BASE_URL + '/quotation',
      {
        method: 'POST',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          amount,
          mode,
          agent_id: agentId,
          transaction_type_code: transactionTypeCode,
          currency_id: currencyId,
          country_id: countryId,
          source_currency_id: sourceCurrencyId
        })
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Got wrong status code while generating quotation');
    }
  }

  async getAgents(
    countryId: number,
    currencyId: number,
    transactionTypeCode: string
  ): Promise<Agent[]> {
    const urlParams = new URLSearchParams();
    urlParams.append('country_id', countryId.toString());
    urlParams.append('currency_id', currencyId.toString());
    urlParams.append('transaction_type_code', transactionTypeCode);
    const response = await fetch(
      this.config.API_BASE_URL + '/agents?' + urlParams.toString(),
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).agents
    } else {
      throw new Error('Got wrong status code while fetching agents');
    }
  }

  async getAccountTypesForAgent({
    agentId, currencyId, countryId, transactionTypeId
  }: {
    agentId: number;
    currencyId: number;
    countryId: number;
    transactionTypeId: number
  }): Promise<AccountType[]> {
    const query = new URLSearchParams();
    query.append('currency_id', currencyId.toString(10));
    query.append('country_id', countryId.toString(10));
    query.append('transaction_type_id', transactionTypeId.toString(10));
    const response = await fetch(
      this.config.API_BASE_URL + `/agents/${agentId}/account-types?${query.toString()}`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).account_types
    } else {
      throw new Error('Got wrong status code while fetching account types');
    }
  }

  async getLocationsForAgentAndCountry(agentId: number, countryId: number): Promise<Location[]> {
    const response = await fetch(this.config.API_BASE_URL + `/agents/${agentId}/locations?country_id=${countryId}`);
    if (response.ok) {
      return (await response.json()).locations
    } else {
      throw new Error('Got wrong status code while fetching agent locations');
    }
  }

  async getCubaZones(): Promise<CubaZones> {
    const response = await fetch(
      this.config.API_BASE_URL + '/countries/cuba/zones',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Could not get cuba zones.')
    }
    return response.json()
  }


  async checkout(
    paymentMethod: 'REVOLUPAY' | 'PAYLANDS',
    concept: string,
    quotationId: number,
    receiver: Beneficiary,
    locationId?: number,
    senderRegionCode?: string,
    promotionId?: number
  ): Promise<{
    revolupay_order_id?: number;
    revolupay_order_reference?: string;
    transaction_id: number;
    paylands_payment_url?: string;
  }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/checkout',
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY,
        },
        body: JSON.stringify({
          payment_method: paymentMethod,
          concept,
          quotation_id:quotationId,
          receiver,
          location_id: locationId,
          sender_region_code: senderRegionCode,
          promotion_id: promotionId
        })
      }
    )
    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error('Got wrong status code while checking out');
    }
  }

  async hasRevoluPAYOrderBeenPaid(
    revolupayOrderId: number
  ): Promise<boolean> {
    const response = await fetch(
      this.config.API_BASE_URL + '/revolupay_orders/' + revolupayOrderId + '/payment',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,

        }
      }
    );
    if (response.ok) {
      return true
    } else if (response.status === 404) {
      return false;
    } else {
      throw new Error('Got wrong status code while checking if payment has been paid');
    }
  }

  async getMyProfile(): Promise<UserProfile> {
    const response = await fetch(
      this.config.API_BASE_URL + '/me',
      {
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Got wrong status code while getting profile info.');
    }
  }

  async addContact(contact: {
    first_name: string;
    last_name: string;
    id_card_country_id: number;
    id_card_type: IdCardType;
    id_card_number: string;
    id_card_expiry_date?: string;
    id_card_issue_date?: string;
    residence_country_id?: number;
    postal_code?: string;
    region?: string;
    address?: string;
    city?: string;
    phone: string;
    email: string;
  }): Promise<number> {
    const response = await fetch(
      this.config.API_BASE_URL + '/contacts',
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY,
        },
        body: JSON.stringify(contact)
      }
    )
    if (!response.ok) {
      throw new Error('Got wrong status code while adding contact.');
    }
    return (await response.json()).id;
  }


  async getContacts(): Promise<Contact[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/contacts',
      {
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).contacts.map((d: any) => d.contact);
    } else {
      throw new Error('Got wrong status code while getting contacts.');
    }
  }

  async deleteContact(id: number) {
    const response = await fetch(
      this.config.API_BASE_URL + `/contacts/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (!response.ok) {
      throw new Error('Got wrong status code while deleting contacts.');
    }
  }

  async getTransactions(limit: number, offset: number): Promise<{ transactions: Transaction[], count: number }> {
    const response = await fetch(
      this.config.API_BASE_URL + `/transactions?limit=${limit}&offset=${offset}&order=desc`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY,
        },
      }
    )
    if (!response.ok) {
      throw new Error('Got wrong status code while getting transactions.');
    }
    return await response.json();
  }

  async getRegions(countryId: number): Promise<Region[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/countries/${countryId}/regions`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    )
    if (!response.ok) {
      throw new Error('Got wrong status code while fetching country regions.');
    }
    return (await response.json()).regions;
  }

  async getLocations(
    request: {
      countryId: number;
      currencyId: number;
      transactionTypeId: number;
      lat: number;
      long: number;
      radius?: number;
      agentId?: number
    }): Promise<LocationListItem[]> {
    const query = new URLSearchParams();
    query.append('country_id', request.countryId.toString(10));
    query.append('currency_id', request.currencyId.toString(10));
    query.append('transaction_type_id', request.transactionTypeId.toString(10));
    query.append('lat', request.lat.toString(10));
    query.append('long', request.long.toString(10));
    if (!request.radius) {
      query.append('radius', '100');
    } else {
      query.append('radius', request.radius.toString(10));
    }
    if (request.agentId) {
      query.append('agent_id', request.agentId.toString(10));
    }
    const response = await fetch(
      this.config.API_BASE_URL + '/locations?' + query.toString(),
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).locations;
    } else {
      throw new Error('Could not fetch locations');
    }
  }

  async getActivePromotions(): Promise<PromotionListItem[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/promotions',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).promotions;
    } else {
      throw new Error('Could not fetch promotions');
    }
  }

  async getBestPromotion(productId: number, sourceSurenncyId: number): Promise<Promotion | null> {
    const response = await fetch(
      this.config.API_BASE_URL + `/checkout/promotion?product_id=${productId}&source_currency_id=${sourceSurenncyId}`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'Authorization': 'Bearer ' + this.auth.getAccessToken()
        },
      }
    );
    if (response.ok) {
      return response.json();
    } else if (response.status === 404) {
      return null;
    }
    throw new Error('Could not fetch promotion');
  }

  async getVoucherPromotion(voucher: string, productId: number, sourceCurrencyId: number): Promise<{ promotion?: Promotion, error?: PromotionVerificationError }> {
    const response = await fetch(
      this.config.API_BASE_URL + `/voucher?voucher=${voucher}&product_id=${productId}&source_currency_id=${sourceCurrencyId}`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'Authorization': 'Bearer ' + this.auth.getAccessToken()
        },
      }
    );
    if (response.ok) {
      return { promotion: (await response.json()), error: undefined };
    } else if (response.status === 404) {
      return { promotion: undefined, error: (await response.json()).error };
    }
    throw new Error('Could not get voucher promotion');
  }

  async createAccount(payload: {
    challenge: string,
    phone_number: string,
    password: string,
    first_name: string,
    last_name: string,
    email: string
  }): Promise<{ success: boolean, errors?: { phoneInUse: boolean } }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/users',
      {
        method: 'POST',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json',
        },
        body: JSON.stringify(payload)
      }
    );
    if (response.ok) {
      return { success: true }
    } else if (response.status === 409) {
      return { success: false, errors: { phoneInUse: true } }
    } else {
      throw new Error('Could not create account');
    }
  }

  async resendCode(phoneNumber: string, challenge: string) {
    const response = await fetch(
      this.config.API_BASE_URL + `/users/${phoneNumber}/validation_code`,
      {
        method: 'POST',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json'
        },
        body: JSON.stringify({ challenge })
      }
    );
    if (!response.ok) {
      throw new Error('Could not resend validation code ');
    }
  }

  async verifyAccount(phoneNumber: string, verificationCode: string, locale: string) {
    const response = await fetch(
      this.config.API_BASE_URL + `/users`,
      {
        method: 'PATCH',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          username: phoneNumber,
          verification_code: verificationCode,
          locale
        })
      }
    );
    if (!response.ok) {
      throw new Error('Could not validate account');
    }
  }

  async getSecurityQuestions(lang: string): Promise<SecurityQuestion[]> {
    const response = await fetch(
      this.config.API_BASE_URL + `/users/security_questions?lang=${lang}`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        }
      }
    );
    if (response.ok) {
      return (await response.json()).security_questions;
    }
    throw new Error('Could not fetch security questions');
  }

  async getUploadUrl(contentType: string): Promise<{ fileId: string, signedUrl: string }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/users/documents',
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY,
        },
        body: JSON.stringify({ content_type: contentType })
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      return {
        fileId: jsonResponse.file_id,
        signedUrl: jsonResponse.signed_url
      }
    }
    throw new Error('Could not request file upload url')
  }

  async uploadFile(file: File | Blob, signedUrl: string) {
    const response = await fetch(signedUrl, { method: 'PUT', body: file });
    if (!response.ok) {
      throw new Error('Could not upload file to s3');
    }
  }

  async updateProfile(payload: {
    address: string;
    city: string;
    region: string;
    postal_code: string;
    country_id: number;
    birth_date: string;
    avatar_file_id: string;
  }) {
    const response = await fetch(
      this.config.API_BASE_URL + '/users/profile',
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY,
        },
        body: JSON.stringify(payload)
      }
    );
    if (!response.ok) {
      throw new Error('Could not update profile.')
    }
  }

  async addKyc(payload: {
    country_id: number;
    type: 'NATIONAL_ID_CARD' | 'PASSPORT';
    expiry_date: string;
    front_image_file_id: string;
    back_image_file_id?: string;
    number?: string;
  }) {
    const response = await fetch(
      this.config.API_BASE_URL + '/users/kyc',
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'content-type': 'application/json',
          'x-tenant-key': this.config.TENANT_KEY,
        },
        body: JSON.stringify(payload)
      }
    );
    if (!response.ok) {
      throw new Error('Could not add kyc.')
    }
  }

  async getExchangeRateMarkups(): Promise<ExchangeMarkup[]> {
    const response = await fetch(
      this.config.API_BASE_URL + '/currencies/exchange_markups',
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Could not get exchange markups.')
    }
    return (await response.json()).exchange_markups;
  }

  async getPaymentUrlForTransaction(transactionId: string): Promise<{ paylands_payment_url: string }> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/transactions/${transactionId}/paylands/url`,
      {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Could not get payments url.')
    }
    return response.json();
  }

  async getSecurityQuestionsForUser(username: string): Promise<SecurityQuestion[]> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/me/security_questions/${username}`,
      {
        method: 'GET',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Could not get security questions.')
    }
    return (await response.json()).security_questions;
  }

  async answerSecurityQuestion(username: string, challenge: string, questionId?: string, answer?: string) {
    let answers: { id: string; value: string; }[] = [];
    if (questionId && answer) {
      answers = [
        {
          id: questionId,
          value: answer
        }
      ];
    }
    const response = await fetch(
      `${this.config.API_BASE_URL}/me/security_questions/${username}`,
      {
        method: 'POST',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          security_answers: answers,
          challenge
        })
      }
    );
    if (!response.ok) {
      throw new Error('Could not reply to security question')
    }
  }

  async resetPassword(username: string, securityCode: string, password: string) {
    const response = await fetch(
      `${this.config.API_BASE_URL}/me/reset_password/${username}`,
      {
        method: 'POST',
        headers: {
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          new_password: password,
          security_code: securityCode
        })
      }
    );
    if (!response.ok) {
      throw new Error('Could not reset password.')
    }
  }

  async updateEmail(email: string) {
    const response = await fetch(
      `${this.config.API_BASE_URL}/me/email`,
      {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
        })
      }
    );
    if (!response.ok) {
      throw new Error('Could not update email.')
    }
  }

  async updatePin(oldPin: string, newPin: string) {
    const response = await fetch(
      `${this.config.API_BASE_URL}/me/password`,
      {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + this.auth.getAccessToken(),
          'x-tenant-key': this.config.TENANT_KEY,
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          password: oldPin,
          new_password: newPin
        })
      }
    );
    if (!response.ok) {
      throw new Error('Could not update pin.')
    }
  }

}
