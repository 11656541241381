import React from 'react';
import { Grid, Typography, CircularProgress, TextField, makeStyles, Button } from '@material-ui/core';
import { useContext, useApi } from '../../context';
import { TransactionTypeCode } from '../../services/types/transactionType.type';
import { Redirect, useHistory } from 'react-router-dom';
import utils from '../../services/utils.service';
import { Agent } from '../../services/types/agent.type';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TransferWizard, TransferWizardStep } from '../wizard';
import { useTranslation } from 'react-i18next';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { LocationsModal } from '../../locations/locationsModal';
import { QuotationInfo } from './quotationInfo';
import { Quotation } from '../../services/types/quotation.type';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export const AgentSelection = () => {
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const productSelectionParams = context.data.productSelectionParams

  if (
    !productSelectionParams ||
    ![
      TransactionTypeCode.HOME_DELIVERY,
      TransactionTypeCode.CASH_TO_AGENT
    ].includes(productSelectionParams.transactionType.code)
  ) {
    return (
      <Redirect to='/' />
    )
  }

  const { t } = useTranslation(['transferOptions', 'accountType']);

  const [quotation, setQuotation] = React.useState<Quotation>();
  const [loadingAgents, setLoadingAgents] = React.useState(true);
  const [loadingProduct, setLoadingProduct] = React.useState(true);
  const [agents, setAgents] = React.useState<Agent[]>([]);
  const [selectedAgent, selectAgent] = React.useState<Agent | null>(null);
  const [showLocationModal, setShowLocationModal] = React.useState(false);

  const loading = loadingAgents || loadingProduct;

  const selectProduct = () => {
    context.setData({
      quotation,
      productAttributes: {
        agent: selectedAgent!!,
      }
    });
    history.push('/transfer/beneficiary')
  }

  React.useEffect(() => {
    setLoadingAgents(true);
    utils.runAsync(async () => {
      const theAgents = await api.getAgents(
        productSelectionParams.country.id,
        productSelectionParams.currency.id,
        productSelectionParams.transactionType.code
      );
      setAgents(theAgents);
      if (theAgents.length === 1) {
        selectAgent(theAgents[0]);
      } else if (context.data.productAttributes?.agent && theAgents.find(a => a.id === context.data.productAttributes?.agent?.id)) {
        selectAgent(context.data.productAttributes?.agent);
      }
    }, () => {
      setLoadingAgents(false);
    });
  }, []);

  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoadingProduct(true);
      if (selectedAgent) {
        const quotation = await api.getQuotation(
          productSelectionParams.country.id,
          productSelectionParams.currency.id,
          productSelectionParams.sourceCurrency.id,
          productSelectionParams.transactionType.code,
          productSelectionParams.amount,
          productSelectionParams.mode,
          selectedAgent!.id
        );
        setQuotation(quotation);
      } else {
        setQuotation(undefined);
      }
    }, () => {
      setLoadingProduct(false);
    });
  }, [selectedAgent]);

  const classes = useStyles();

  return (
    <>
      <LocationsModal
        open={showLocationModal}
        onClose={() => setShowLocationModal(false)}
        agentId={selectedAgent?.id}
        transactionTypeId={context.data.productSelectionParams!.transactionType.id}
        countryId={context.data.productSelectionParams!.country.id}
        currencyId={context.data.productSelectionParams!.currency.id}
      />
      <TransferWizard
        step={TransferWizardStep.OPTIONS}
        back={() => history.push('/transfer/destination')}
        canGoNext={!loading && quotation !== null}
        next={selectProduct}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('AGENT_SELECTION_INTRO')}
            </Typography>
          </Grid>
          {!loadingAgents &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  selectAgent(value);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                value={selectedAgent}
                options={agents}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.name}
                renderOption={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Choose an agent')}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
          }
          {selectedAgent && context.data.productSelectionParams?.transactionType.code === TransactionTypeCode.CASH_TO_AGENT &&
            <Grid container item justify='center' xs={12}>
              <Typography gutterBottom style={{ marginBottom: 10 }}>{t('PICKUP_LOCATIONS_DESCRIPTION')}</Typography>
              <Button
                startIcon={<PinDropIcon />}
                color='secondary'
                size='large'
                variant='outlined'
                onClick={() => {
                  setShowLocationModal(true);
                }}
              >{t('VIEW_PICKUP_LOCATIONS')}
              </Button>
            </Grid>
          }
          {!loading && quotation &&
            <Grid item xs={12}>
              <QuotationInfo quotation={quotation} />
            </Grid>
          }
          {loading &&
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </TransferWizard>
    </>
  );
}