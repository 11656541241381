import React from 'react';
import { Box, Button, Drawer, Grid, IconButton, Link, makeStyles, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';
import drawerLogo from '../assets/img/sendity-drawer.png';
import waveImg from '../assets/img/wave.png';
import CloseIcon from '@material-ui/icons/Close';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YoutubeIcon from '@material-ui/icons/YouTube';
import { useContext } from '../context';
import LanguageIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Config } from '../config';

const config = new Config();

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  logo: {
    maxWidth: '100%'
  },
  closeIcon: {
    color: theme.palette.primary.contrastText,
    cursor: 'pointer'
  },
  wave: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  upperMenu: {
    flex: 4,
  },
  lowerMenu: {
    flex: 1,
  },
  icon: {
    color: theme.palette.primary.contrastText
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  drawerPaper: {
    height: '100%',
  }
}));


const LanguageButton = () => {
  const { t, i18n } = useTranslation('layout');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeLanguageMenu = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language: 'en' | 'es') => {
    i18n.changeLanguage(language);
    closeLanguageMenu();
  }

  const getCurrentLanguage = () => {
    if (i18n.language.includes('es')) {
      return t('SPANISH');
    } else {
      return t('ENGLISH');
    }
  }

  return (
    <>
      <StyledButton aria-controls='language-menu' aria-haspopup='true' onClick={openLanguageMenu}>
        <LanguageIcon style={{ marginRight: 10 }} />
        {getCurrentLanguage()}
        <ExpandMoreIcon fontSize='small' />
      </StyledButton>
      <Menu
        id='language-menu-drawer'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLanguageMenu}
      >
        <MenuItem onClick={() => setLanguage('en')}>{t('ENGLISH')}</MenuItem>
        <MenuItem onClick={() => setLanguage('es')}>{t('SPANISH')}</MenuItem>
      </Menu>
    </>
  );
};

const StyledButton = withStyles(theme => ({
  label: {
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    fontSize: 30,
    textAlign: 'center'
  },
}))(Button);

const StyledRoundButton = withStyles(theme => ({
  root: {
    borderRadius: 50,
    width: 300,
    maxWidth: '100%',
    padding: 14,

  },
  label: {
    textTransform: 'none',
    fontSize: 20,
  },
}))(Button);

const StyledLightRoundButton = withStyles(theme => ({
  root: {
    borderRadius: 50,
    borderColor: '#FFF',
    color: theme.palette.primary.contrastText,
    width: 300,
    maxWidth: '100%',
    padding: 14,
  },
  label: {
    textTransform: 'none',
    fontSize: 20,
  },
}))(Button);

export const MenuDrawer = ({
  open,
  onClose
}: {
  open: boolean,
  onClose: () => void
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('layout');
  const context = useContext();

  return (
    <Drawer
      anchor='top'
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
      onClose={() => onClose()}>
      <img src={waveImg} className={classes.wave} />
      <Box className={classes.main}>
        <Grid container className={classes.upperMenu} direction='row' spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems='center'>
              <Grid item xs={6}>
                <img className={classes.logo} src={drawerLogo} />
              </Grid>
              <Grid container item xs={6} justify='flex-end'>
                <IconButton onClick={() => onClose()}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row' spacing={2}>
              <Grid container item xs={12} justify='center'>
                <LanguageButton />
              </Grid>
              <Grid container item xs={12} justify='center'>
                <StyledButton onClick={() => { onClose() }} component={RouterLink} to='/transfer' color='inherit'>{t('NEW TRANSFER')}</StyledButton>
              </Grid>
              <Grid container item xs={12} justify='center'>
                <StyledButton onClick={() => onClose()} href={i18n.language==='es'?'https://www.sendity.com/contacto':'https://www.sendity.com/en/contact'} color='inherit'>{t('HELP')}</StyledButton>
              </Grid>
                <Grid container item xs={12} justify='center'>
                  <StyledLightRoundButton component={Link} href={config.MAIN_REDIRECT_URL} onClick={()=>onClose()} color='primary' variant='outlined'>
                    {t('MY ACCOUNT')}
                  </StyledLightRoundButton>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.lowerMenu} alignItems='center'>
          <Grid item xs={12}>
            <Box className={classes.iconBox}>
              <IconButton component='a' rel='noopener' target='_blank' href='https://www.facebook.com/sendityapp' aria-label='facebook'>
                <FacebookIcon className={classes.icon} />
              </IconButton>
              <IconButton component='a' rel='noopener' target='_blank' href='https://www.youtube.com/channel/UCHmC0BHD5VJwlVV-S3Y0WLQ' aria-label='youtube'>
                <YoutubeIcon className={classes.icon} />
              </IconButton>
              <IconButton component='a' rel='noopener' target='_blank' href='https://www.instagram.com/appsendity' aria-label='instagram'>
                <InstagramIcon className={classes.icon} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

      </Box>
    </Drawer>
  );
}