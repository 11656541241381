import React from 'react';
// import { Grid, Typography, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import okImg from '../assets/img/success.svg'
import { useContext } from '../context';
import { Redirect } from 'react-router-dom';
import { Config } from '../config';

const config = new Config();
export const PaymentOk = () => {
  // const { t } = useTranslation('payment');

  const context = useContext();
  React.useEffect(() => {
    context.reset();
  }, []);

  return (
    // <Container style={{ height: '100%' }}>
    //   <Grid container justify='center' alignContent='center' spacing={4} style={{ height: '100%' }}>
    //     <Grid item xs={12}>
    //       <Typography variant='h4' align='center'>
    //         {t('PAYMENT_OK_TITLE')}
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={12}>
    //       <Typography variant='subtitle1' align='center'>
    //         {t('PAYMENT_OK_SUBTITLE')}
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={12} container justify='center'>
    //       <img src={okImg} style={{ maxWidth: 200 }} />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <Typography align='center'>
    //         {t('PAYMENT_OK_DESCRIPTION')}
    //       </Typography>
    //     </Grid>
    //   </Grid>
    // </Container>
    <Redirect to={`${config.MAIN_REDIRECT_URL}/remittance-completed`} />
  );
}